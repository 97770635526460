import { ExpandLess, ExpandMore, UnfoldMore } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { FoodDatabaseTableResponseRowRelatedPatientDraftReportsInner, RecentMeal } from 'api/generated/MNT';
import { AxiosResponse } from 'axios';
import { formatHumanTime } from 'food-editor/components/HumanTime';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link, useSearchParams } from 'react-router-dom';
import { useSocketIOClient } from 'socketio/SocketIOService';
import { useDebounce } from 'usehooks-ts';
import { downloadCSV } from 'utils';

export type RxTableColumn<T> =
  & {
    // Must have either a field or id, to use RxTableColumnSelect
    header: React.ReactNode,
  }
  & (
    | { id: string, field?: keyof T }
    | { id?: string, field: keyof T }
    | { id: string, field: keyof T }
  )
  & {
    getValue?: (row: T) => any,

    hidden?: boolean,

    href?: (row: T) => string,

    sortable?: boolean,
    sortField?: keyof T,

    cellRenderer?: (props: RxTableCellProps<T>) => JSX.Element,

    filter?: RxTableFilterType,
    // Column can be selected/unselected in RxTableColumnSelect, True by default
    selectable?: boolean,
  };

export function rxTableColumnGetId(column: RxTableColumn<any>) {
  const res = column.id
    ? `id:${column.id}`
    : column.field
    ? `field:${(column as any).field}`
    : null;
  if (!res) {
    throw new Error('Column must have either id or field: ' + JSON.stringify(column));
  }
  return res;
}

export type RxTableFilterEnumValue =
  | { value: string | '__null__', label: string }
  | string;

export type RxTableFilterBasicTypes =
  | 'numeric'
  | 'date'
  | 'string'
  | 'boolean';

export type RxTableFilterCommon = {
  defaultCmp?: keyof typeof FILTER_CMP,
};

export type RxTableFilterType =
  | RxTableFilterBasicTypes
  | { type: 'enum', values: RxTableFilterEnumValue[] } & RxTableFilterCommon
  | { type: RxTableFilterBasicTypes } & RxTableFilterCommon;

export type RxTableFilterValue<ValType> =
  | ValType
  | Partial<{
    eq: ValType,
    ne: ValType,
    gt: ValType,
    gte: ValType,
    lt: ValType,
    lte: ValType,
    in: ValType[],
    contains: ValType,
    contains_any: ValType[],
  }>;

const FILTER_CMP = {
  eq: '=',
  ne: '!=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  in: 'in',
  contains: 'contains',
  contains_any: 'contains any',
};

export type RxTableSortField<T> = {
  field: keyof T,
  direction: 'asc' | 'desc',
};

export type RxTableSort<T> = RxTableSortField<T>[];

export type RxTableFilter<T> = {
  [key in keyof T]?: RxTableFilterValue<T[key]>;
};

type ApiTableResponse<T> = {
  has_more?: boolean,
  rows?: T[],
};

type ApiTableParams = {
  filter?: any,
  limit?: any,
  offset?: any,
  order?: any,
};

export type RxTableData<T> = {
  rows: T[],
  filter: RxTableFilter<T>,
  sort: RxTableSort<T>,
  query: UseQueryResult<unknown>,

  setSort: (sort: RxTableSortField<T>) => void,
  setFilter: (filter: RxTableFilter<T>) => void,
};

export const useSearchParamsDebounce = (delay: number = 500) => {
  const [_winParams, _] = useSearchParams();
  const [params, setParams] = useState<Record<string, string>>({});
  const io = useSocketIOClient();

  useEffect(() => {
    setParams(Object.fromEntries(_winParams.entries()));
  }, [_winParams]);

  const paramsDeboucned = useDebounce(params, delay);
  useEffect(() => {
    const searchParams = new URLSearchParams(paramsDeboucned);
    window.history.replaceState({}, '', '?' + searchParams.toString());
    io.socket?.emit('presence:ident', {
      location: window.location.href.slice(window.location.origin.length),
    });
  }, [paramsDeboucned]);

  return [
    params,
    setParams,
  ] as const;
};

export function useRxTableFilterSearchParams<T>(opts: {
  keyPrefix: string,
  useSearchParams?: boolean,
  initialFilter?: RxTableFilter<T>,
  initialSort?: RxTableSort<T>,
}) {
  const { keyPrefix } = opts;
  const [filter, _setFilter] = useState<RxTableFilter<T>>({});
  const [sort, _setSort] = useState<RxTableSort<T>>([]);

  const [q, setQ] = useSearchParamsDebounce();

  useEffect(() => {
    if (!opts.useSearchParams) {
      return;
    }
    _setFilter(JSON.parse(q[`${keyPrefix}-filter`] || '{}'));
    _setSort(JSON.parse(q[`${keyPrefix}-sort`] || '[]'));
    console.log('Set from query:', q[`${keyPrefix}-filter`], q[`${keyPrefix}-sort`]);
  }, [keyPrefix, q]);

  useEffect(() => {
    if (!opts.useSearchParams) {
      _setFilter(opts.initialFilter || {});
      _setSort(opts.initialSort || []);
      return;
    }
    console.log('Set from initial:', opts.initialFilter, opts.initialSort);
    setQ(prev => ({
      ...prev,
      [`${keyPrefix}-sort`]: opts.initialSort
        ? JSON.stringify(opts.initialSort || [])
        : prev[`${keyPrefix}-sort`] ?? '[]',
      [`${keyPrefix}-filter`]: JSON.stringify({
        ...JSON.parse(prev[`${keyPrefix}-filter`] || '{}'),
        ...(opts.initialFilter || {}),
      }),
    }));
  }, [opts.keyPrefix, JSON.stringify(opts.initialFilter), JSON.stringify(opts.initialSort)]);

  return {
    filter,
    setFilter: (filter: RxTableFilter<T>) => {
      if (!opts.useSearchParams) {
        _setFilter(filter);
        return;
      }
      setQ(prev => ({
        ...prev,
        [`${keyPrefix}-filter`]: JSON.stringify(filter),
      }));
    },

    sort,
    setSort: (sort: RxTableSortField<T>) => {
      if (!opts.useSearchParams) {
        _setSort([sort]);
        return;
      }
      setQ(prev => ({
        ...prev,
        [`${keyPrefix}-sort`]: JSON.stringify([sort]),
      }));
    },
  };
}

export function useRxTableData<T>(opts: {
  queryKey: string,
  apiEndpoint: (params: ApiTableParams) => Promise<AxiosResponse<ApiTableResponse<T>>>,
  useSearchParams?: boolean,
  initialFilter?: RxTableFilter<T>,
  initialSort?: RxTableSort<T>,
  queryOptions?: Omit<UseQueryOptions, 'queryKey'>,
  queryLimit?: number,
}): RxTableData<T> {
  const { sort, filter, setSort, setFilter } = useRxTableFilterSearchParams({
    keyPrefix: opts.queryKey,
    useSearchParams: opts.useSearchParams,
    initialFilter: opts.initialFilter,
    initialSort: opts.initialSort,
  });

  const queryKey = useDebounce([opts.queryKey, filter, sort], 100);

  const query = useQuery(queryKey, async () => {
    const orderStr = sort.map(s => `${s.direction == 'asc' ? '+' : '-'}${String(s.field)}`).join(',');
    const res = await opts.apiEndpoint({
      filter: JSON.stringify(filter),
      order: orderStr,
      limit: opts.queryLimit,
    });
    return res.data.rows;
  }, opts.queryOptions || {});

  return {
    rows: (query.data as any) || [],
    filter,
    setFilter,
    sort,
    setSort,
    query,
  };
}

export type RxTableHeaderProps<T> = {
  column: RxTableColumn<T>,
  data: RxTableData<T>,
};

export function RxTableHeader<T>(props: RxTableHeaderProps<T>) {
  const { column, data } = props;
  const sortField = column.field || column.sortField;
  const sortDirection = data.sort.find(s => s.field == sortField)?.direction;

  const onClick = () => {
    if (!sortField) {
      return;
    }

    data.setSort({
      field: sortField,
      direction: sortDirection == 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <th
      onClick={onClick}
      style={{
        cursor: column.sortable ? 'pointer' : 'default',
        whiteSpace: 'nowrap',
      }}
    >
      <span style={{ whiteSpace: 'normal' }}>{props.column.header}</span>
      {column.sortable && (
        !sortDirection
          ? <UnfoldMore />
          : sortDirection == 'asc'
          ? <ExpandLess />
          : sortDirection == 'desc'
          ? <ExpandMore />
          : null
      )}
    </th>
  );
}

export type RxTableCellProps<T> = {
  row: T,
  column: RxTableColumn<T>,
  data: RxTableData<T>,
};

export function rxTableCellDefaultRenderer<T>(props: RxTableCellProps<T>) {
  const { row, column } = props;
  const value = column.getValue
    ? column.getValue(row)
    : column.field
    ? row[column.field as keyof T]
    : null;
  const href = column.href?.(row);

  if (href) {
    return <Link to={href} onClick={e => e.stopPropagation()}>{'' + value}</Link>;
  }
  return <span>{'' + value}</span>;
}

export function RxTableCell<T>(props: RxTableCellProps<T>) {
  const { row, column } = props;
  const renderer = column.cellRenderer || rxTableCellDefaultRenderer;
  const value = renderer(props);
  const href = column.href?.(row);

  return (
    <td
      style={{
        cursor: href ? 'pointer' : 'default',
      }}
      onClick={() => href && window.open(href, '_blank')}
    >
      {value}
    </td>
  );
}

export function RxTableFilter<T>(props: {
  column: RxTableColumn<T>,
  data: RxTableData<T>,
}) {
  const { column, data } = props;
  const filter = column.filter;
  const field = column.field as keyof T;

  const filterVal = data.filter[field] as any;
  const value = !filterVal
    ? filterVal
    : typeof filterVal == 'object'
    ? Object.values(filterVal)[0] // Todo: multiple values
    : filterVal;

  const filterCmp = filterVal && typeof filterVal == 'object'
    ? Object.keys(filterVal)[0] // Todo: multiple values
    : typeof filter == 'object' && filter.defaultCmp
    ? filter.defaultCmp
    : 'eq';

  const update = (cmp: string | null, val: any) => {
    if (!val) {
      data.setFilter({
        ...data.filter,
        [field]: {
          [cmp || 'eq']: undefined,
        },
      });
      return;
    }

    data.setFilter({
      ...data.filter,
      [field]: { [cmp || 'eq']: val },
    });
  };

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    update(filterCmp, val);
  };

  const setFilterCmp = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value;
    update(val, value);
  };

  if (!filter) {
    return null;
  }

  const filterType = typeof filter == 'string' ? filter : filter.type;

  return (
    <Form.Row style={{ display: 'flex' }}>
      <Form.Group className="col-11" style={{ paddingRight: 10 }}>
        <Form.Label>{column.header}</Form.Label>
        {filter == 'numeric' || (typeof filter == 'object' && filter.type == 'numeric')
          ? (
            <Form.Control
              type="number"
              value={value ?? ''}
              onChange={setValue}
            />
          )
          : filter == 'date' || (typeof filter == 'object' && filter.type == 'date')
          ? (
            <Form.Control
              type="date"
              value={value ?? ''}
              onChange={setValue}
            />
          )
          : filter == 'string' || (typeof filter == 'object' && filter.type == 'string')
          ? (
            <Form.Control
              type="text"
              value={value ?? ''}
              onChange={setValue}
            />
          )
          : filter == 'boolean' || (typeof filter == 'object' && filter.type == 'boolean')
          ? (
            <Form.Control
              as="select"
              onChange={setValue}
              value={value ?? ''}
            >
              <option value="">(Any)</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          )
          : filter.type == 'enum'
          ? (
            <Form.Control as="select" onChange={setValue} value={value ?? ''}>
              <option value="">(Any)</option>
              {filter.values.map(val => {
                const { label, value } = typeof val == 'string' ? { label: val, value: val } : val;
                return (
                  <option
                    key={value}
                    value={value}
                  >
                    {label}
                  </option>
                );
              })}
            </Form.Control>
          )
          : (
            <div>
              Unknown filter: <code>{JSON.stringify(filter)}</code>
            </div>
          )}
      </Form.Group>
      <Form.Group className="col-1">
        <Form.Label>Compare</Form.Label>
        <Form.Control as="select" onChange={setFilterCmp} value={filterCmp}>
          {Object.entries(FILTER_CMP).map(([key, value]) => (
            <option
              key={key}
              value={key}
            >
              {value}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </Form.Row>
  );
}

export function RxTableFilters<T>(props: {
  columns: RxTableColumn<T>[],
  data: RxTableData<T>,
}) {
  const { columns, data } = props;
  const filters = useMemo(() => {
    return columns.filter(column => !!column.filter);
  }, [columns]);

  if (!filters.length) {
    return null;
  }

  if (!filters.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {filters.map((column, index) => <RxTableFilter key={index} column={column} data={data} />)}
    </Stack>
  );
}

export function RxTableColumnSelect<T>(props: {
  columns: RxTableColumn<T>[],
  selectedColumns: RxTableColumn<T>[],
  setSelectedColumns: (columns: RxTableColumn<T>[]) => void,
}) {
  const { columns, selectedColumns } = props;
  const [lastColumns, setLastColumns] = useState<RxTableColumn<T>[]>(columns);
  const [selectedColIds, setSelectedColIds] = useState<string[]>(columns.map(rxTableColumnGetId));
  const [selectedColRecord, setSelectedColRecord] = useState<Record<string, string[]>>({});

  const handleColumnChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedColIds(selected);
  };

  useEffect(() => {
    props.setSelectedColumns(
      columns.filter(column => {
        if (column.selectable !== undefined && !column.selectable) {
          return true;
        }
        return selectedColIds.includes(rxTableColumnGetId(column));
      }),
    );
  }, [selectedColIds]);

  const getColumnsIdStr = (targetColumns: RxTableColumn<T>[]) => {
    return targetColumns.map(rxTableColumnGetId).join();
  };

  useEffect(() => {
    const lastColumnsIdStr = getColumnsIdStr(lastColumns);
    setSelectedColRecord({
      ...selectedColRecord,
      [lastColumnsIdStr]: selectedColIds,
    });

    const newColumnsIdStr = getColumnsIdStr(columns);

    const newColSelectedColIds = !selectedColRecord[newColumnsIdStr]
      ? columns.map(rxTableColumnGetId)
      : selectedColRecord[newColumnsIdStr];
    setSelectedColIds(newColSelectedColIds);

    setLastColumns(columns);
  }, [columns]);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel>Table Columns</InputLabel>
      <Select
        id="rxtable-column-select"
        fullWidth
        multiple
        value={selectedColIds}
        onChange={handleColumnChange}
        input={<OutlinedInput label="Table Columns" />}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 190,
              width: 250,
            },
          },
        }}
        renderValue={(selected) => {
          const headers = selectedColumns.map(item => item.header);
          return headers.map((header, idx) => <span key={idx}>{idx > 0 && ', '}{header}</span>);
        }}
      >
        {columns.map((column, index) => (
          <MenuItem key={index} value={rxTableColumnGetId(column)}>
            <Checkbox
              disabled={column.selectable !== undefined && !column.selectable}
              checked={selectedColIds.includes(rxTableColumnGetId(column))}
            />
            <ListItemText primary={column.header} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function RxTable<T>(props: {
  columns: RxTableColumn<T>[],
  data: RxTableData<T>,
  allowColumnSelect?: boolean,
  allowFilters?: boolean,
  allowDownload?: boolean,
}) {
  const { columns, data, allowColumnSelect, allowFilters } = props;

  const visibleColumns = useMemo(() => {
    return columns.filter(c => !c.hidden);
  }, [columns]);
  const [selectedColumns, setSelectedColumns] = useState(visibleColumns);

  const downloadTableAsCsv = () => {
    const headerRow = selectedColumns.map(column => {
      if (column.id == 'mic') {
        return 'M / I / C';
      }
      return column.header?.toString();
    });

    const csvContent = [headerRow.join(',')];

    data.rows.forEach(row => {
      const rowData = selectedColumns.map(column => {
        if (column.id == 'mic' || column.id == 'flags') {
          return column.getValue ? column.getValue(row) : '';
        }

        const cellValue = row[column.field as keyof T];

        if (column.field == 'recent_meals') {
          const recentMeals = cellValue as RecentMeal[];
          return recentMeals
            ? `"${
              recentMeals.map(meal =>
                `${meal.queue_id} (${meal.meal_id}/${meal.patient_id}) ${meal.patient_is_priority ? 'P' : ''}`
              ).join('\n')
            }"`
            : '';
        }

        if (column.field == 'related_patient_draft_reports') {
          const reportDrafts = cellValue as FoodDatabaseTableResponseRowRelatedPatientDraftReportsInner[];
          return reportDrafts
            ? `"${reportDrafts.map(r => `${r.patient_report_id} (${r.patient_id})`).join('\n')}"`
            : '';
        }

        if ((column.field as string)?.includes('time')) {
          return formatHumanTime(cellValue as string);
        }

        if (column.field == 'patient_id') {
          return cellValue ? `"${cellValue} ${row['patient_is_priority' as keyof T] ? 'P' : ''}"` : '';
        }

        return cellValue ? `"${cellValue.toString()}"` : '';
      });
      csvContent.push(rowData.join(','));
    });

    downloadCSV(csvContent.join('\n'), 'table_data');
  };

  return (
    <Stack spacing={3}>
      {allowFilters && <RxTableFilters columns={columns} data={data} />}
      {allowColumnSelect && (
        <RxTableColumnSelect
          columns={visibleColumns}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
        />
      )}
      {props.allowDownload && (
        <Button variant="contained" onClick={downloadTableAsCsv} style={{ width: '20%' }}>
          Download table as CSV
        </Button>
      )}
      <Table striped bordered hover style={{ width: '100%' }}>
        <thead>
          <tr>
            {selectedColumns.map((column, index) => <RxTableHeader key={index} column={column} data={data} />)}
          </tr>
        </thead>
        {data.query.isLoading
          ? (
            <tbody>
              <tr>
                <td colSpan={99}>
                  Loading…
                </td>
              </tr>
            </tbody>
          )
          : data.query.isError
          ? (
            <tbody>
              <tr>
                <td colSpan={99}>
                  <i>
                    <span>Error: {'' + data.query.error}</span>
                  </i>
                </td>
              </tr>
            </tbody>
          )
          : !data.rows.length
          ? (
            <tbody>
              <tr>
                <td colSpan={99}>
                  <i>
                    <span>Nothing to show…</span>
                  </i>
                </td>
              </tr>
            </tbody>
          )
          : (
            <tbody>
              {data.rows.map((row, index) => (
                <tr key={index}>
                  {selectedColumns.map((column, index) => (
                    <RxTableCell
                      key={index}
                      row={row}
                      column={column}
                      data={data}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          )}
      </Table>
    </Stack>
  );
}
