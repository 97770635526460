import { CancelledError, hashQueryKey, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { sleep } from 'async-result/utils';
import { DateTime } from 'luxon';
import * as React from 'react';

export const useQueryNeverRefetch = {
  refetchInterval: 0,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchIntervalInBackground: false,
} as const;

export type UseQueryDebounceOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
  debounce: true | number,
};

export function useQueryDebounce<T>(options: UseQueryDebounceOptions<T>) {
  const currentQueryKeyRef = React.useRef(options.queryKey);
  currentQueryKeyRef.current = options.queryKey;
  return useQuery({
    ...options,
    queryFn: async (...args) => {
      await sleep(options.debounce === true ? 500 : options.debounce);
      const isSameQuery = hashQueryKey(options.queryKey!) === hashQueryKey(currentQueryKeyRef.current!);
      if (!isSameQuery) {
        throw new CancelledError();
      }
      return options.queryFn!(...args);
    },
  });
}

export function useUpdatingState<T>(
  value: T,
  nullDefault: NonNullable<T>,
  useEffectChangeList?: any[],
): [NonNullable<T>, (newValue: T) => void] {
  const [state, setState] = React.useState<T>(value);
  React.useEffect(() => {
    setState(value ?? nullDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, ...(useEffectChangeList || [])]);

  return [state ?? nullDefault, setState];
}

/**
 * Replace all spaces in `s` with non-breaking spaces.
 */
export function nbsp(s: string): string {
  return s.replaceAll(' ', '\u00A0');
}

/**
 * Encodes an object into a query string.
 *
 * NOTE: it's unlikely this should be used directly; instead, see ``buildUrl``.
 *
 * > encodeQueryParams({ a: 'b', c: 'd' })
 * 'a=b&c=d'
 */
export const encodeQueryParams = (params: { [key: string]: string | number | boolean | null | undefined }) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as any)}`)
    .join('&');
};

/**
 * Parses a query string into an object.
 *
 * > parseQueryParams('a=b&c=d')
 * { a: 'b', c: 'd' }
 */
export const parseQueryParams = (params: string) => {
  const parsed = new URLSearchParams(params);
  const result: { [key: string]: string } = {};
  parsed.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

/**
 * Compares two given params
 *  - primarily used for when food is custom, since food name will be in query intead of path
 *  - Note: the result does not depend on whether the input param string has '?' at the start
 *
 * > cmpCustomFoodQueryParams('?initial-name=custom%20fish%20fry', 'initial-name=custom+fish+fry&compare=')
 * true
 *
 * > cmpCustomFoodQueryParams('?initial-name=custom%20fish%20fry', 'initial-name=custom+fish+fry&compare=', true)
 * false
 */
export const cmpCustomFoodQueryParams = (params1: string, params2: string, exactMatch?: boolean) => {
  const parsedParams1 = parseQueryParams(params1);
  const parsedParams2 = parseQueryParams(params2);

  if (!parsedParams1['initial-name'] || !parsedParams2['initial-name']) {
    return false;
  }

  if (!exactMatch) {
    return parsedParams1['initial-name'] === parsedParams2['initial-name'];
  }

  if (parsedParams1.length !== parsedParams2.length) {
    return false;
  }

  return Object.keys(parsedParams1).every(key => parsedParams1[key] === parsedParams2[key]);
};

/**
 * Builds a URL from a base URL and a set of query parameters.
 *
 * > buildUrl('https://example.com', { a: 'b', c: 'd' })
 * 'https://example.com?a=b&c=d'
 * > buildUrl('https://example.com?e=f', { a: 'b', c: 'd' })
 * 'https://example.com?e=f&a=b&c=d'
 */
export const buildUrl = (
  url: string,
  params?: string | { [key: string]: string | number | boolean | null | undefined },
) => {
  if (!params) {
    return url;
  }

  const paramsStr = typeof params === 'string' ? params.replace('?', '') : encodeQueryParams(params);
  const queryJoiner = paramsStr.length == 0 ? '' : url.includes('?') ? '&' : '?';
  return url + queryJoiner + paramsStr;
};

/**
 * Parses an useQuery error and returns error message as string
 *
 * > parseQueryError(query.error)
 * 'Request failed with status code 404: User 99999 not found'
 */
export const parseQueryError = (error: any) => {
  if (!error) {
    return '';
  }

  const errorMessage = error.message;
  const errorData = error.response?.data;

  if (errorMessage && errorData) {
    return errorMessage + ': ' + errorData.message;
  }

  return 'Error Message: ' + errorMessage + '\nError Data: ' + errorData;
};

export const useDocumentVisibility = () => {
  const [isVisible, setIsVisible] = React.useState(!document.hidden);

  React.useEffect(() => {
    const onVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return isVisible;
};

export const downloadCSV = (csvString: string, filename?: string) => {
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename ?? 'download'}_${DateTime.now()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
