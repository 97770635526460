/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppApiMealGetMealAuditorUrl200Response } from '../models';
// @ts-ignore
import { AppApiMealGetMealsTable200Response } from '../models';
// @ts-ignore
import { AppApiMealPhotoPostMealPhotosByIdZipRequest } from '../models';
// @ts-ignore
import { CreateMealItemRequest } from '../models';
// @ts-ignore
import { CreateMealRequest } from '../models';
// @ts-ignore
import { DisaggregatedMealResponse } from '../models';
// @ts-ignore
import { MealChangeLogResponse } from '../models';
// @ts-ignore
import { MealItemResponse } from '../models';
// @ts-ignore
import { MealResponse } from '../models';
// @ts-ignore
import { MealSubstitutionSuggestionsResponse } from '../models';
// @ts-ignore
import { UpdateMealItemRequest } from '../models';
// @ts-ignore
import { UpdateMealRequest } from '../models';
/**
 * MealApi - axios parameter creator
 * @export
 */
export const MealApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the meal with the add-ons split from meal items
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDisaggregatedMealItemsGetDisaggregatedMeal: async (patient_id: number, meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiDisaggregatedMealItemsGetDisaggregatedMeal', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiDisaggregatedMealItemsGetDisaggregatedMeal', 'meal_id', meal_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/disaggregated_meal`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the meal and all meal items of to this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealDeleteMeal: async (patient_id: number, meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealDeleteMeal', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealDeleteMeal', 'meal_id', meal_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {string} [X_Rx_Change_Context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealDeleteMealItem: async (patient_id: number, meal_id: number, meal_item_id: number, X_Rx_Change_Context?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealDeleteMealItem', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealDeleteMealItem', 'meal_id', meal_id)
            // verify required parameter 'meal_item_id' is not null or undefined
            assertParamExists('appApiMealDeleteMealItem', 'meal_item_id', meal_item_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items/{meal_item_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"meal_item_id"}}`, encodeURIComponent(String(meal_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (X_Rx_Change_Context !== undefined && X_Rx_Change_Context !== null) {
                localVarHeaderParameter['X-Rx-Change-Context'] = String(X_Rx_Change_Context);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meal item\'s complete info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetCompleteMealItem: async (patient_id: number, meal_id: number, meal_item_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetCompleteMealItem', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealGetCompleteMealItem', 'meal_id', meal_id)
            // verify required parameter 'meal_item_id' is not null or undefined
            assertParamExists('appApiMealGetCompleteMealItem', 'meal_item_id', meal_item_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items/{meal_item_id}/complete`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"meal_item_id"}}`, encodeURIComponent(String(meal_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a meal information
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMeal: async (patient_id: number, meal_id: number, include_archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMeal', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealGetMeal', 'meal_id', meal_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include_archived !== undefined) {
                localVarQueryParameter['include_archived'] = include_archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TEMPORARY METHOD - returns a URL showing this meal in the auditor
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealAuditorUrl: async (patient_id: number, meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMealAuditorUrl', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealGetMealAuditorUrl', 'meal_id', meal_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/auditor-url`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets change logs for the meal and all meal items of this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealChangeLogs: async (patient_id: number, meal_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMealChangeLogs', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealGetMealChangeLogs', 'meal_id', meal_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/change-logs`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meal item\'s info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealItem: async (patient_id: number, meal_id: number, meal_item_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMealItem', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealGetMealItem', 'meal_id', meal_id)
            // verify required parameter 'meal_item_id' is not null or undefined
            assertParamExists('appApiMealGetMealItem', 'meal_item_id', meal_item_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items/{meal_item_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"meal_item_id"}}`, encodeURIComponent(String(meal_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meal items by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealItemsByFoodName: async (food_name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'food_name' is not null or undefined
            assertParamExists('appApiMealGetMealItemsByFoodName', 'food_name', food_name)
            const localVarPath = `/meal_items/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (food_name !== undefined) {
                localVarQueryParameter['food_name'] = food_name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the user\'s meal
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [include_deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMeals: async (patient_id: number, date_since: string, date_until: string, include_deleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMeals', 'patient_id', patient_id)
            // verify required parameter 'date_since' is not null or undefined
            assertParamExists('appApiMealGetMeals', 'date_since', date_since)
            // verify required parameter 'date_until' is not null or undefined
            assertParamExists('appApiMealGetMeals', 'date_until', date_until)
            const localVarPath = `/patients/{patient_id}/meals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date_since !== undefined) {
                localVarQueryParameter['date_since'] = date_since;
            }

            if (date_until !== undefined) {
                localVarQueryParameter['date_until'] = date_until;
            }

            if (include_deleted !== undefined) {
                localVarQueryParameter['include_deleted'] = include_deleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Query the user\'s meal dates since the last report
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealsSinceLastReport: async (patient_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMealsSinceLastReport', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/meals_since_last_report`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a table response of meals for a patient
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {'basic' | 'complete'} [nutrition_response_level] 
         * @param {boolean} [disaggregate_meal_items] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealsTable: async (patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, nutrition_response_level?: 'basic' | 'complete', disaggregate_meal_items?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetMealsTable', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/meals_table`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (nutrition_response_level !== undefined) {
                localVarQueryParameter['nutrition_response_level'] = nutrition_response_level;
            }

            if (disaggregate_meal_items !== undefined) {
                localVarQueryParameter['disaggregate_meal_items'] = disaggregate_meal_items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets substitution suggestions, possibly for a meal or a meal item.
         * @param {number} patient_id 
         * @param {number} [meal_id] 
         * @param {number} [meal_item_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetPatientMealSubstitutionSuggestions: async (patient_id: number, meal_id?: number, meal_item_id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealGetPatientMealSubstitutionSuggestions', 'patient_id', patient_id)
            const localVarPath = `/patients/{patient_id}/meals/substitution-suggestions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meal_id !== undefined) {
                localVarQueryParameter['meal_id'] = meal_id;
            }

            if (meal_item_id !== undefined) {
                localVarQueryParameter['meal_item_id'] = meal_item_id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get original user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetOriginalPhoto: async (meal_photo_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_id' is not null or undefined
            assertParamExists('appApiMealPhotoGetOriginalPhoto', 'meal_photo_id', meal_photo_id)
            const localVarPath = `/meal_photos/{meal_photo_id}/original_photo`
                .replace(`{${"meal_photo_id"}}`, encodeURIComponent(String(meal_photo_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get resized user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhoto: async (meal_photo_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_id' is not null or undefined
            assertParamExists('appApiMealPhotoGetPhoto', 'meal_photo_id', meal_photo_id)
            const localVarPath = `/meal_photos/{meal_photo_id}/photo`
                .replace(`{${"meal_photo_id"}}`, encodeURIComponent(String(meal_photo_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The URL returned by `.../photo-url`, using a `?token=...` query parameter for security.
         * @param {number} meal_photo_id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhotoJpg: async (meal_photo_id: number, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_id' is not null or undefined
            assertParamExists('appApiMealPhotoGetPhotoJpg', 'meal_photo_id', meal_photo_id)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('appApiMealPhotoGetPhotoJpg', 'token', token)
            const localVarPath = `/meal_photos/{meal_photo_id}/photo.jpg`
                .replace(`{${"meal_photo_id"}}`, encodeURIComponent(String(meal_photo_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a signed URL which allows direct access to the meal photo.
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhotoUrl: async (meal_photo_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_photo_id' is not null or undefined
            assertParamExists('appApiMealPhotoGetPhotoUrl', 'meal_photo_id', meal_photo_id)
            const localVarPath = `/meal_photos/{meal_photo_id}/photo-url`
                .replace(`{${"meal_photo_id"}}`, encodeURIComponent(String(meal_photo_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patient meal images
         * @param {number} patient_id 
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoPostOriginalPhotosZip: async (patient_id: number, AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPhotoPostOriginalPhotosZip', 'patient_id', patient_id)
            // verify required parameter 'AppApiMealPhotoPostMealPhotosByIdZipRequest' is not null or undefined
            assertParamExists('appApiMealPhotoPostOriginalPhotosZip', 'AppApiMealPhotoPostMealPhotosByIdZipRequest', AppApiMealPhotoPostMealPhotosByIdZipRequest)
            const localVarPath = `/patients/{patient_id}/meal_photos_zip`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(AppApiMealPhotoPostMealPhotosByIdZipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new meal item for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {Array<CreateMealItemRequest>} CreateMealItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItems: async (patient_id: number, meal_id: number, CreateMealItemRequest: Array<CreateMealItemRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPostMealItems', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPostMealItems', 'meal_id', meal_id)
            // verify required parameter 'CreateMealItemRequest' is not null or undefined
            assertParamExists('appApiMealPostMealItems', 'CreateMealItemRequest', CreateMealItemRequest)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateMealItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new meal
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {boolean} [create_queue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMeals: async (patient_id: number, CreateMealRequest: CreateMealRequest, create_queue?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPostMeals', 'patient_id', patient_id)
            // verify required parameter 'CreateMealRequest' is not null or undefined
            assertParamExists('appApiMealPostMeals', 'CreateMealRequest', CreateMealRequest)
            const localVarPath = `/patients/{patient_id}/meals`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (create_queue !== undefined) {
                localVarQueryParameter['create_queue'] = create_queue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(CreateMealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostRefreshMealItem: async (patient_id: number, meal_id: number, meal_item_id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPostRefreshMealItem', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPostRefreshMealItem', 'meal_id', meal_id)
            // verify required parameter 'meal_item_id' is not null or undefined
            assertParamExists('appApiMealPostRefreshMealItem', 'meal_item_id', meal_item_id)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items/{meal_item_id}/refresh`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"meal_item_id"}}`, encodeURIComponent(String(meal_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a set of meals
         * @param {Array<number>} meal_ids 
         * @param {boolean} [auto_escalate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostRefreshMeals: async (meal_ids: Array<number>, auto_escalate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meal_ids' is not null or undefined
            assertParamExists('appApiMealPostRefreshMeals', 'meal_ids', meal_ids)
            const localVarPath = `/patients/refresh_meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (meal_ids) {
                localVarQueryParameter['meal_ids'] = meal_ids;
            }

            if (auto_escalate !== undefined) {
                localVarQueryParameter['auto_escalate'] = auto_escalate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealRequest} UpdateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPutMeal: async (patient_id: number, meal_id: number, UpdateMealRequest: UpdateMealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPutMeal', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPutMeal', 'meal_id', meal_id)
            // verify required parameter 'UpdateMealRequest' is not null or undefined
            assertParamExists('appApiMealPutMeal', 'UpdateMealRequest', UpdateMealRequest)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing meal item in a meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {UpdateMealItemRequest} UpdateMealItemRequest meal item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPutMealItem: async (patient_id: number, meal_id: number, meal_item_id: number, UpdateMealItemRequest: UpdateMealItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patient_id' is not null or undefined
            assertParamExists('appApiMealPutMealItem', 'patient_id', patient_id)
            // verify required parameter 'meal_id' is not null or undefined
            assertParamExists('appApiMealPutMealItem', 'meal_id', meal_id)
            // verify required parameter 'meal_item_id' is not null or undefined
            assertParamExists('appApiMealPutMealItem', 'meal_item_id', meal_item_id)
            // verify required parameter 'UpdateMealItemRequest' is not null or undefined
            assertParamExists('appApiMealPutMealItem', 'UpdateMealItemRequest', UpdateMealItemRequest)
            const localVarPath = `/patients/{patient_id}/meals/{meal_id}/meal_items/{meal_item_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patient_id)))
                .replace(`{${"meal_id"}}`, encodeURIComponent(String(meal_id)))
                .replace(`{${"meal_item_id"}}`, encodeURIComponent(String(meal_item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(UpdateMealItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealApi - functional programming interface
 * @export
 */
export const MealApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the meal with the add-ons split from meal items
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiDisaggregatedMealItemsGetDisaggregatedMeal(patient_id: number, meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisaggregatedMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiDisaggregatedMealItemsGetDisaggregatedMeal(patient_id, meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the meal and all meal items of to this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealDeleteMeal(patient_id: number, meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealDeleteMeal(patient_id, meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {string} [X_Rx_Change_Context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealDeleteMealItem(patient_id: number, meal_id: number, meal_item_id: number, X_Rx_Change_Context?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealDeleteMealItem(patient_id, meal_id, meal_item_id, X_Rx_Change_Context, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the meal item\'s complete info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetCompleteMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetCompleteMealItem(patient_id, meal_id, meal_item_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a meal information
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMeal(patient_id: number, meal_id: number, include_archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMeal(patient_id, meal_id, include_archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary TEMPORARY METHOD - returns a URL showing this meal in the auditor
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealAuditorUrl(patient_id: number, meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiMealGetMealAuditorUrl200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealAuditorUrl(patient_id, meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets change logs for the meal and all meal items of this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealChangeLogs(patient_id: number, meal_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealChangeLogs(patient_id, meal_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the meal item\'s info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealItem(patient_id, meal_id, meal_item_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the meal items by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealItemsByFoodName(food_name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealItemsByFoodName(food_name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the user\'s meal
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [include_deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMeals(patient_id: number, date_since: string, date_until: string, include_deleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMeals(patient_id, date_since, date_until, include_deleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Query the user\'s meal dates since the last report
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealsSinceLastReport(patient_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealsSinceLastReport(patient_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a table response of meals for a patient
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {'basic' | 'complete'} [nutrition_response_level] 
         * @param {boolean} [disaggregate_meal_items] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetMealsTable(patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, nutrition_response_level?: 'basic' | 'complete', disaggregate_meal_items?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppApiMealGetMealsTable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetMealsTable(patient_id, filter, limit, offset, order, nutrition_response_level, disaggregate_meal_items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets substitution suggestions, possibly for a meal or a meal item.
         * @param {number} patient_id 
         * @param {number} [meal_id] 
         * @param {number} [meal_item_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealGetPatientMealSubstitutionSuggestions(patient_id: number, meal_id?: number, meal_item_id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealSubstitutionSuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealGetPatientMealSubstitutionSuggestions(patient_id, meal_id, meal_item_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get original user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoGetOriginalPhoto(meal_photo_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoGetOriginalPhoto(meal_photo_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get resized user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoGetPhoto(meal_photo_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoGetPhoto(meal_photo_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The URL returned by `.../photo-url`, using a `?token=...` query parameter for security.
         * @param {number} meal_photo_id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoGetPhotoJpg(meal_photo_id: number, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoGetPhotoJpg(meal_photo_id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a signed URL which allows direct access to the meal photo.
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoGetPhotoUrl(meal_photo_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoGetPhotoUrl(meal_photo_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patient meal images
         * @param {number} patient_id 
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPhotoPostOriginalPhotosZip(patient_id: number, AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPhotoPostOriginalPhotosZip(patient_id, AppApiMealPhotoPostMealPhotosByIdZipRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new meal item for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {Array<CreateMealItemRequest>} CreateMealItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMealItems(patient_id: number, meal_id: number, CreateMealItemRequest: Array<CreateMealItemRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MealItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMealItems(patient_id, meal_id, CreateMealItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new meal
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {boolean} [create_queue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostMeals(patient_id: number, CreateMealRequest: CreateMealRequest, create_queue?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostMeals(patient_id, CreateMealRequest, create_queue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostRefreshMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostRefreshMealItem(patient_id, meal_id, meal_item_id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a set of meals
         * @param {Array<number>} meal_ids 
         * @param {boolean} [auto_escalate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPostRefreshMeals(meal_ids: Array<number>, auto_escalate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPostRefreshMeals(meal_ids, auto_escalate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealRequest} UpdateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPutMeal(patient_id: number, meal_id: number, UpdateMealRequest: UpdateMealRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPutMeal(patient_id, meal_id, UpdateMealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing meal item in a meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {UpdateMealItemRequest} UpdateMealItemRequest meal item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiMealPutMealItem(patient_id: number, meal_id: number, meal_item_id: number, UpdateMealItemRequest: UpdateMealItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MealItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiMealPutMealItem(patient_id, meal_id, meal_item_id, UpdateMealItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MealApi - factory interface
 * @export
 */
export const MealApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the meal with the add-ons split from meal items
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiDisaggregatedMealItemsGetDisaggregatedMeal(patient_id: number, meal_id: number, options?: any): AxiosPromise<DisaggregatedMealResponse> {
            return localVarFp.appApiDisaggregatedMealItemsGetDisaggregatedMeal(patient_id, meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the meal and all meal items of to this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealDeleteMeal(patient_id: number, meal_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealDeleteMeal(patient_id, meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {string} [X_Rx_Change_Context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealDeleteMealItem(patient_id: number, meal_id: number, meal_item_id: number, X_Rx_Change_Context?: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealDeleteMealItem(patient_id, meal_id, meal_item_id, X_Rx_Change_Context, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meal item\'s complete info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetCompleteMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: any): AxiosPromise<MealItemResponse> {
            return localVarFp.appApiMealGetCompleteMealItem(patient_id, meal_id, meal_item_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a meal information
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {boolean} [include_archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMeal(patient_id: number, meal_id: number, include_archived?: boolean, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiMealGetMeal(patient_id, meal_id, include_archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary TEMPORARY METHOD - returns a URL showing this meal in the auditor
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealAuditorUrl(patient_id: number, meal_id: number, options?: any): AxiosPromise<AppApiMealGetMealAuditorUrl200Response> {
            return localVarFp.appApiMealGetMealAuditorUrl(patient_id, meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets change logs for the meal and all meal items of this meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealChangeLogs(patient_id: number, meal_id: number, options?: any): AxiosPromise<MealChangeLogResponse> {
            return localVarFp.appApiMealGetMealChangeLogs(patient_id, meal_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meal item\'s info
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: any): AxiosPromise<MealItemResponse> {
            return localVarFp.appApiMealGetMealItem(patient_id, meal_id, meal_item_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meal items by food name
         * @param {string} food_name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealItemsByFoodName(food_name: string, options?: any): AxiosPromise<Array<MealItemResponse>> {
            return localVarFp.appApiMealGetMealItemsByFoodName(food_name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the user\'s meal
         * @param {number} patient_id 
         * @param {string} date_since 
         * @param {string} date_until 
         * @param {boolean} [include_deleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMeals(patient_id: number, date_since: string, date_until: string, include_deleted?: boolean, options?: any): AxiosPromise<Array<MealResponse>> {
            return localVarFp.appApiMealGetMeals(patient_id, date_since, date_until, include_deleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Query the user\'s meal dates since the last report
         * @param {number} patient_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealsSinceLastReport(patient_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealGetMealsSinceLastReport(patient_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a table response of meals for a patient
         * @param {number} patient_id 
         * @param {string} [filter] 
         * @param {number} [limit] 
         * @param {string} [offset] 
         * @param {string} [order] 
         * @param {'basic' | 'complete'} [nutrition_response_level] 
         * @param {boolean} [disaggregate_meal_items] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetMealsTable(patient_id: number, filter?: string, limit?: number, offset?: string, order?: string, nutrition_response_level?: 'basic' | 'complete', disaggregate_meal_items?: boolean, options?: any): AxiosPromise<AppApiMealGetMealsTable200Response> {
            return localVarFp.appApiMealGetMealsTable(patient_id, filter, limit, offset, order, nutrition_response_level, disaggregate_meal_items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets substitution suggestions, possibly for a meal or a meal item.
         * @param {number} patient_id 
         * @param {number} [meal_id] 
         * @param {number} [meal_item_id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealGetPatientMealSubstitutionSuggestions(patient_id: number, meal_id?: number, meal_item_id?: number, options?: any): AxiosPromise<MealSubstitutionSuggestionsResponse> {
            return localVarFp.appApiMealGetPatientMealSubstitutionSuggestions(patient_id, meal_id, meal_item_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get original user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetOriginalPhoto(meal_photo_id: number, options?: any): AxiosPromise<any> {
            return localVarFp.appApiMealPhotoGetOriginalPhoto(meal_photo_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get resized user uploaded meal photo contents by id
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhoto(meal_photo_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealPhotoGetPhoto(meal_photo_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The URL returned by `.../photo-url`, using a `?token=...` query parameter for security.
         * @param {number} meal_photo_id 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhotoJpg(meal_photo_id: number, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealPhotoGetPhotoJpg(meal_photo_id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a signed URL which allows direct access to the meal photo.
         * @param {number} meal_photo_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoGetPhotoUrl(meal_photo_id: number, options?: any): AxiosPromise<void> {
            return localVarFp.appApiMealPhotoGetPhotoUrl(meal_photo_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patient meal images
         * @param {number} patient_id 
         * @param {AppApiMealPhotoPostMealPhotosByIdZipRequest} AppApiMealPhotoPostMealPhotosByIdZipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPhotoPostOriginalPhotosZip(patient_id: number, AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest, options?: any): AxiosPromise<any> {
            return localVarFp.appApiMealPhotoPostOriginalPhotosZip(patient_id, AppApiMealPhotoPostMealPhotosByIdZipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new meal item for the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {Array<CreateMealItemRequest>} CreateMealItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMealItems(patient_id: number, meal_id: number, CreateMealItemRequest: Array<CreateMealItemRequest>, options?: any): AxiosPromise<Array<MealItemResponse>> {
            return localVarFp.appApiMealPostMealItems(patient_id, meal_id, CreateMealItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new meal
         * @param {number} patient_id 
         * @param {CreateMealRequest} CreateMealRequest 
         * @param {boolean} [create_queue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostMeals(patient_id: number, CreateMealRequest: CreateMealRequest, create_queue?: boolean, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiMealPostMeals(patient_id, CreateMealRequest, create_queue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a meal item
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostRefreshMealItem(patient_id: number, meal_id: number, meal_item_id: number, options?: any): AxiosPromise<MealItemResponse> {
            return localVarFp.appApiMealPostRefreshMealItem(patient_id, meal_id, meal_item_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary refresh (recalculate) the nutrients for a set of meals
         * @param {Array<number>} meal_ids 
         * @param {boolean} [auto_escalate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPostRefreshMeals(meal_ids: Array<number>, auto_escalate?: boolean, options?: any): AxiosPromise<MealItemResponse> {
            return localVarFp.appApiMealPostRefreshMeals(meal_ids, auto_escalate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {UpdateMealRequest} UpdateMealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPutMeal(patient_id: number, meal_id: number, UpdateMealRequest: UpdateMealRequest, options?: any): AxiosPromise<MealResponse> {
            return localVarFp.appApiMealPutMeal(patient_id, meal_id, UpdateMealRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing meal item in a meal
         * @param {number} patient_id 
         * @param {number} meal_id 
         * @param {number} meal_item_id 
         * @param {UpdateMealItemRequest} UpdateMealItemRequest meal item
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiMealPutMealItem(patient_id: number, meal_id: number, meal_item_id: number, UpdateMealItemRequest: UpdateMealItemRequest, options?: any): AxiosPromise<MealItemResponse> {
            return localVarFp.appApiMealPutMealItem(patient_id, meal_id, meal_item_id, UpdateMealItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiDisaggregatedMealItemsGetDisaggregatedMeal operation in MealApi.
 * @export
 * @interface MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMealRequest
 */
export interface MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMealRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMeal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMeal
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiMealDeleteMeal operation in MealApi.
 * @export
 * @interface MealApiAppApiMealDeleteMealRequest
 */
export interface MealApiAppApiMealDeleteMealRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealDeleteMeal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealDeleteMeal
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiMealDeleteMealItem operation in MealApi.
 * @export
 * @interface MealApiAppApiMealDeleteMealItemRequest
 */
export interface MealApiAppApiMealDeleteMealItemRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealDeleteMealItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealDeleteMealItem
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealDeleteMealItem
     */
    readonly meal_item_id: number

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealDeleteMealItem
     */
    readonly X_Rx_Change_Context?: string
}

/**
 * Request parameters for appApiMealGetCompleteMealItem operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetCompleteMealItemRequest
 */
export interface MealApiAppApiMealGetCompleteMealItemRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetCompleteMealItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetCompleteMealItem
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetCompleteMealItem
     */
    readonly meal_item_id: number
}

/**
 * Request parameters for appApiMealGetMeal operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealRequest
 */
export interface MealApiAppApiMealGetMealRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMeal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMeal
     */
    readonly meal_id: number

    /**
     * 
     * @type {boolean}
     * @memberof MealApiAppApiMealGetMeal
     */
    readonly include_archived?: boolean
}

/**
 * Request parameters for appApiMealGetMealAuditorUrl operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealAuditorUrlRequest
 */
export interface MealApiAppApiMealGetMealAuditorUrlRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealAuditorUrl
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealAuditorUrl
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiMealGetMealChangeLogs operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealChangeLogsRequest
 */
export interface MealApiAppApiMealGetMealChangeLogsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealChangeLogs
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealChangeLogs
     */
    readonly meal_id: number
}

/**
 * Request parameters for appApiMealGetMealItem operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealItemRequest
 */
export interface MealApiAppApiMealGetMealItemRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealItem
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealItem
     */
    readonly meal_item_id: number
}

/**
 * Request parameters for appApiMealGetMealItemsByFoodName operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealItemsByFoodNameRequest
 */
export interface MealApiAppApiMealGetMealItemsByFoodNameRequest {
    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMealItemsByFoodName
     */
    readonly food_name: string
}

/**
 * Request parameters for appApiMealGetMeals operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealsRequest
 */
export interface MealApiAppApiMealGetMealsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMeals
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMeals
     */
    readonly date_since: string

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMeals
     */
    readonly date_until: string

    /**
     * 
     * @type {boolean}
     * @memberof MealApiAppApiMealGetMeals
     */
    readonly include_deleted?: boolean
}

/**
 * Request parameters for appApiMealGetMealsSinceLastReport operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealsSinceLastReportRequest
 */
export interface MealApiAppApiMealGetMealsSinceLastReportRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealsSinceLastReport
     */
    readonly patient_id: number
}

/**
 * Request parameters for appApiMealGetMealsTable operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetMealsTableRequest
 */
export interface MealApiAppApiMealGetMealsTableRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly patient_id: number

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly limit?: number

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly offset?: string

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly order?: string

    /**
     * 
     * @type {'basic' | 'complete'}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly nutrition_response_level?: 'basic' | 'complete'

    /**
     * 
     * @type {boolean}
     * @memberof MealApiAppApiMealGetMealsTable
     */
    readonly disaggregate_meal_items?: boolean
}

/**
 * Request parameters for appApiMealGetPatientMealSubstitutionSuggestions operation in MealApi.
 * @export
 * @interface MealApiAppApiMealGetPatientMealSubstitutionSuggestionsRequest
 */
export interface MealApiAppApiMealGetPatientMealSubstitutionSuggestionsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetPatientMealSubstitutionSuggestions
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetPatientMealSubstitutionSuggestions
     */
    readonly meal_id?: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealGetPatientMealSubstitutionSuggestions
     */
    readonly meal_item_id?: number
}

/**
 * Request parameters for appApiMealPhotoGetOriginalPhoto operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPhotoGetOriginalPhotoRequest
 */
export interface MealApiAppApiMealPhotoGetOriginalPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPhotoGetOriginalPhoto
     */
    readonly meal_photo_id: number
}

/**
 * Request parameters for appApiMealPhotoGetPhoto operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPhotoGetPhotoRequest
 */
export interface MealApiAppApiMealPhotoGetPhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPhotoGetPhoto
     */
    readonly meal_photo_id: number
}

/**
 * Request parameters for appApiMealPhotoGetPhotoJpg operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPhotoGetPhotoJpgRequest
 */
export interface MealApiAppApiMealPhotoGetPhotoJpgRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPhotoGetPhotoJpg
     */
    readonly meal_photo_id: number

    /**
     * 
     * @type {string}
     * @memberof MealApiAppApiMealPhotoGetPhotoJpg
     */
    readonly token: string
}

/**
 * Request parameters for appApiMealPhotoGetPhotoUrl operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPhotoGetPhotoUrlRequest
 */
export interface MealApiAppApiMealPhotoGetPhotoUrlRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPhotoGetPhotoUrl
     */
    readonly meal_photo_id: number
}

/**
 * Request parameters for appApiMealPhotoPostOriginalPhotosZip operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPhotoPostOriginalPhotosZipRequest
 */
export interface MealApiAppApiMealPhotoPostOriginalPhotosZipRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPhotoPostOriginalPhotosZip
     */
    readonly patient_id: number

    /**
     * 
     * @type {AppApiMealPhotoPostMealPhotosByIdZipRequest}
     * @memberof MealApiAppApiMealPhotoPostOriginalPhotosZip
     */
    readonly AppApiMealPhotoPostMealPhotosByIdZipRequest: AppApiMealPhotoPostMealPhotosByIdZipRequest
}

/**
 * Request parameters for appApiMealPostMealItems operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPostMealItemsRequest
 */
export interface MealApiAppApiMealPostMealItemsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostMealItems
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostMealItems
     */
    readonly meal_id: number

    /**
     * 
     * @type {Array<CreateMealItemRequest>}
     * @memberof MealApiAppApiMealPostMealItems
     */
    readonly CreateMealItemRequest: Array<CreateMealItemRequest>
}

/**
 * Request parameters for appApiMealPostMeals operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPostMealsRequest
 */
export interface MealApiAppApiMealPostMealsRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostMeals
     */
    readonly patient_id: number

    /**
     * 
     * @type {CreateMealRequest}
     * @memberof MealApiAppApiMealPostMeals
     */
    readonly CreateMealRequest: CreateMealRequest

    /**
     * 
     * @type {boolean}
     * @memberof MealApiAppApiMealPostMeals
     */
    readonly create_queue?: boolean
}

/**
 * Request parameters for appApiMealPostRefreshMealItem operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPostRefreshMealItemRequest
 */
export interface MealApiAppApiMealPostRefreshMealItemRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostRefreshMealItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostRefreshMealItem
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPostRefreshMealItem
     */
    readonly meal_item_id: number
}

/**
 * Request parameters for appApiMealPostRefreshMeals operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPostRefreshMealsRequest
 */
export interface MealApiAppApiMealPostRefreshMealsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof MealApiAppApiMealPostRefreshMeals
     */
    readonly meal_ids: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MealApiAppApiMealPostRefreshMeals
     */
    readonly auto_escalate?: boolean
}

/**
 * Request parameters for appApiMealPutMeal operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPutMealRequest
 */
export interface MealApiAppApiMealPutMealRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPutMeal
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPutMeal
     */
    readonly meal_id: number

    /**
     * 
     * @type {UpdateMealRequest}
     * @memberof MealApiAppApiMealPutMeal
     */
    readonly UpdateMealRequest: UpdateMealRequest
}

/**
 * Request parameters for appApiMealPutMealItem operation in MealApi.
 * @export
 * @interface MealApiAppApiMealPutMealItemRequest
 */
export interface MealApiAppApiMealPutMealItemRequest {
    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPutMealItem
     */
    readonly patient_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPutMealItem
     */
    readonly meal_id: number

    /**
     * 
     * @type {number}
     * @memberof MealApiAppApiMealPutMealItem
     */
    readonly meal_item_id: number

    /**
     * meal item
     * @type {UpdateMealItemRequest}
     * @memberof MealApiAppApiMealPutMealItem
     */
    readonly UpdateMealItemRequest: UpdateMealItemRequest
}

/**
 * MealApi - object-oriented interface
 * @export
 * @class MealApi
 * @extends {BaseAPI}
 */
export class MealApi extends BaseAPI {
    /**
     * 
     * @summary Get the meal with the add-ons split from meal items
     * @param {MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiDisaggregatedMealItemsGetDisaggregatedMeal(requestParameters: MealApiAppApiDisaggregatedMealItemsGetDisaggregatedMealRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiDisaggregatedMealItemsGetDisaggregatedMeal(requestParameters.patient_id, requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the meal and all meal items of to this meal
     * @param {MealApiAppApiMealDeleteMealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealDeleteMeal(requestParameters: MealApiAppApiMealDeleteMealRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealDeleteMeal(requestParameters.patient_id, requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a meal item
     * @param {MealApiAppApiMealDeleteMealItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealDeleteMealItem(requestParameters: MealApiAppApiMealDeleteMealItemRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealDeleteMealItem(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, requestParameters.X_Rx_Change_Context, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the meal item\'s complete info
     * @param {MealApiAppApiMealGetCompleteMealItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetCompleteMealItem(requestParameters: MealApiAppApiMealGetCompleteMealItemRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetCompleteMealItem(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a meal information
     * @param {MealApiAppApiMealGetMealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMeal(requestParameters: MealApiAppApiMealGetMealRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMeal(requestParameters.patient_id, requestParameters.meal_id, requestParameters.include_archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary TEMPORARY METHOD - returns a URL showing this meal in the auditor
     * @param {MealApiAppApiMealGetMealAuditorUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealAuditorUrl(requestParameters: MealApiAppApiMealGetMealAuditorUrlRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealAuditorUrl(requestParameters.patient_id, requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets change logs for the meal and all meal items of this meal
     * @param {MealApiAppApiMealGetMealChangeLogsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealChangeLogs(requestParameters: MealApiAppApiMealGetMealChangeLogsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealChangeLogs(requestParameters.patient_id, requestParameters.meal_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the meal item\'s info
     * @param {MealApiAppApiMealGetMealItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealItem(requestParameters: MealApiAppApiMealGetMealItemRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealItem(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the meal items by food name
     * @param {MealApiAppApiMealGetMealItemsByFoodNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealItemsByFoodName(requestParameters: MealApiAppApiMealGetMealItemsByFoodNameRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealItemsByFoodName(requestParameters.food_name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the user\'s meal
     * @param {MealApiAppApiMealGetMealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMeals(requestParameters: MealApiAppApiMealGetMealsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMeals(requestParameters.patient_id, requestParameters.date_since, requestParameters.date_until, requestParameters.include_deleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Query the user\'s meal dates since the last report
     * @param {MealApiAppApiMealGetMealsSinceLastReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealsSinceLastReport(requestParameters: MealApiAppApiMealGetMealsSinceLastReportRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealsSinceLastReport(requestParameters.patient_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a table response of meals for a patient
     * @param {MealApiAppApiMealGetMealsTableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetMealsTable(requestParameters: MealApiAppApiMealGetMealsTableRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetMealsTable(requestParameters.patient_id, requestParameters.filter, requestParameters.limit, requestParameters.offset, requestParameters.order, requestParameters.nutrition_response_level, requestParameters.disaggregate_meal_items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets substitution suggestions, possibly for a meal or a meal item.
     * @param {MealApiAppApiMealGetPatientMealSubstitutionSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealGetPatientMealSubstitutionSuggestions(requestParameters: MealApiAppApiMealGetPatientMealSubstitutionSuggestionsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealGetPatientMealSubstitutionSuggestions(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get original user uploaded meal photo contents by id
     * @param {MealApiAppApiMealPhotoGetOriginalPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPhotoGetOriginalPhoto(requestParameters: MealApiAppApiMealPhotoGetOriginalPhotoRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPhotoGetOriginalPhoto(requestParameters.meal_photo_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get resized user uploaded meal photo contents by id
     * @param {MealApiAppApiMealPhotoGetPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPhotoGetPhoto(requestParameters: MealApiAppApiMealPhotoGetPhotoRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPhotoGetPhoto(requestParameters.meal_photo_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The URL returned by `.../photo-url`, using a `?token=...` query parameter for security.
     * @param {MealApiAppApiMealPhotoGetPhotoJpgRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPhotoGetPhotoJpg(requestParameters: MealApiAppApiMealPhotoGetPhotoJpgRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPhotoGetPhotoJpg(requestParameters.meal_photo_id, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a signed URL which allows direct access to the meal photo.
     * @param {MealApiAppApiMealPhotoGetPhotoUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPhotoGetPhotoUrl(requestParameters: MealApiAppApiMealPhotoGetPhotoUrlRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPhotoGetPhotoUrl(requestParameters.meal_photo_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patient meal images
     * @param {MealApiAppApiMealPhotoPostOriginalPhotosZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPhotoPostOriginalPhotosZip(requestParameters: MealApiAppApiMealPhotoPostOriginalPhotosZipRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPhotoPostOriginalPhotosZip(requestParameters.patient_id, requestParameters.AppApiMealPhotoPostMealPhotosByIdZipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new meal item for the meal
     * @param {MealApiAppApiMealPostMealItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPostMealItems(requestParameters: MealApiAppApiMealPostMealItemsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPostMealItems(requestParameters.patient_id, requestParameters.meal_id, requestParameters.CreateMealItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new meal
     * @param {MealApiAppApiMealPostMealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPostMeals(requestParameters: MealApiAppApiMealPostMealsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPostMeals(requestParameters.patient_id, requestParameters.CreateMealRequest, requestParameters.create_queue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh (recalculate) the nutrients for a meal item
     * @param {MealApiAppApiMealPostRefreshMealItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPostRefreshMealItem(requestParameters: MealApiAppApiMealPostRefreshMealItemRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPostRefreshMealItem(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary refresh (recalculate) the nutrients for a set of meals
     * @param {MealApiAppApiMealPostRefreshMealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPostRefreshMeals(requestParameters: MealApiAppApiMealPostRefreshMealsRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPostRefreshMeals(requestParameters.meal_ids, requestParameters.auto_escalate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the meal
     * @param {MealApiAppApiMealPutMealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPutMeal(requestParameters: MealApiAppApiMealPutMealRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPutMeal(requestParameters.patient_id, requestParameters.meal_id, requestParameters.UpdateMealRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing meal item in a meal
     * @param {MealApiAppApiMealPutMealItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealApi
     */
    public appApiMealPutMealItem(requestParameters: MealApiAppApiMealPutMealItemRequest, options?: AxiosRequestConfig) {
        return MealApiFp(this.configuration).appApiMealPutMealItem(requestParameters.patient_id, requestParameters.meal_id, requestParameters.meal_item_id, requestParameters.UpdateMealItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
