import { LoadingOutlined } from '@ant-design/icons';
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@mui/icons-material';
import {
  Alert,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { apiConfig, dataReviewApi, foodApi } from 'api';
import { Configuration, FoodApi, MealPhotoQueueResponse, OauthApi } from 'api/generated/MNT';
import { authenticate, ReviewerAuthInfo } from 'apiClients/auth';
import { MealQueueItem } from 'apiClients/mpq';
import { getReviewList, getReviewListFromQueueIds } from 'apiClients/review';
import axios from 'axios';
import MainCard from 'components/MainCard';
import { PreviewImage } from 'components/queue/PreviewImage';
import { QueueSummaryIcons } from 'components/QueueSummaryIcons';
import { config } from 'config';
import { useAuth } from 'context/appContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAsyncResult } from 'react-use-async-result';
import { downloadCSV } from 'utils';

export const ReplayLogsPage = () => {
  return (
    <MainCard>
      <ReplayLogs />
    </MainCard>
  );
};

type ReplayLogsFormData = {
  patientID: string,
  dateFrom: string,
  dateTo: string,
  environment: Environment,
  limit: number,
  username: string,
};

type Environment = 'local' | 'staging' | 'production';
type EnvironmentData = {
  apiUrl: string,
  auditorUrl: string,
};

const environments: Record<Environment, EnvironmentData> = {
  local: { apiUrl: 'http://localhost:1480', auditorUrl: 'http://localhost:1484' },
  staging: { apiUrl: 'https://stg-api.rxdev.co', auditorUrl: 'https://stg-auditor.savoro.app' },
  production: { apiUrl: 'https://api.rxfoodapp.com', auditorUrl: 'https://auditor.savoro.app' },
};

const environmentOptions: Environment[] = Object.keys(environments) as Environment[];
const configEnvToEnvironment = () => {
  if (config.ENV == 'local') {
    return 'local';
  }
  if (config.ENV == 'stg') {
    return 'staging';
  }
  return 'production';
};
const limitOptions = [1, 5, 10, 25, 50, 75, 100, 125, 150, 175, 200];

export const ReplayLogs = () => {
  const { authInfo } = useAuth();
  const [formData, setFormData] = useLocalStorage<ReplayLogsFormData>('replayLogsFormData', {
    patientID: authInfo?.reviewer_id
      ? authInfo.reviewer_id.toString()
      : '',
    environment: 'local',
    dateFrom: moment.utc().format('YYYY-MM-DD'),
    dateTo: moment.utc().format('YYYY-MM-DD'),
    limit: 1,
    username: '',
  });
  const [password, setPassword] = useState<string>('');
  const [queue, setQueue] = useState<MealQueueItem[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [resetSentItems, setResetSentItems] = useState<boolean>(false);
  const [createdMealUrls, setCreatedMealUrls] = useState<string[]>([]);
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');
  const foodResultsRes = useAsyncResult<MealPhotoQueueResponse[] | null>();
  const [inputType, setInputType] = useState<'form' | 'queue'>('form');
  const [inputQueueIds, setInputQueueIds] = useState<string>('');
  const [queryQueueIds, setQueryQueueIds] = useState<string>('');

  const submitReplayLogs = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsSubmitted(false);
    setResetSentItems(false);
    getResults().then(() => {
      setIsSubmitted(true);
    });
  };

  const getResults = async () => {
    setFormErrorMessage('');
    if (!authInfo) {
      setFormErrorMessage('User does not have sufficient authorization to perform this action.');
      return null;
    }

    if (inputType == 'queue' && queryQueueIds) {
      const queueIds = queryQueueIds.match(/\d+/g)?.splice(0, 50).map(numStr => parseInt(numStr, 10)) || [];
      if (!queueIds) {
        return [];
      }
      const mpq = await getReviewListFromQueueIds({
        reviewer_id: authInfo.reviewer_id,
        queue_ids: queueIds,
      });
      return mpq;
    }

    if (formData.patientID !== '' && !parseInt(formData.patientID)) {
      setFormErrorMessage('Invalid User Id');
      return null;
    }
    const { access_token } = authInfo;
    const mpq = await getReviewList({
      reviewer_id: authInfo.reviewer_id,
      date_since: formData.dateFrom,
      date_until: formData.dateTo,
      patient_id: parseInt(formData.patientID) || null,
      access_token: access_token,
    });
    return mpq.filter((item, i) => i < formData.limit);
  };

  useEffect(() => {
    if (!foodResultsRes.isDone) {
      return;
    }
    if (!foodResultsRes.result) {
      setQueue([]);
      return;
    }
    setQueue(foodResultsRes.result);
  }, [foodResultsRes.isDone]);

  useEffect(() => {
    setQueue([]);
    if (foodResultsRes.isError && (inputType != 'queue' || queryQueueIds != '')) {
      setFormErrorMessage('Could not retrieve results, please recheck the form inputs');
    }
  }, [foodResultsRes.isError]);

  useEffect(() => {
    foodResultsRes.bind(getResults());
  }, [formData.patientID, formData.dateFrom, formData.dateTo, formData.limit, inputType, queryQueueIds]);

  const handleDownloadCSV = () => {
    const headerRow = ['meal_url'];
    const csvContent = [headerRow.join(',')];
    createdMealUrls.forEach((mu) => {
      csvContent.push([mu].join(','));
    });
    downloadCSV(csvContent.join('\n'), 'replay_meals');
  };

  return (
    <>
      <ButtonGroup variant="outlined" fullWidth size="large" style={{ marginBottom: 10 }}>
        <Button
          disableElevation
          variant={inputType === 'form' ? 'contained' : 'outlined'}
          onClick={() => {
            setInputType('form');
            setIsSubmitted(false);
          }}
          style={{ width: '10%' }}
        >
          Patient
        </Button>
        <Button
          disableElevation
          variant={inputType === 'queue' ? 'contained' : 'outlined'}
          onClick={() => {
            setInputType('queue');
            setQueryQueueIds('');
            setIsSubmitted(false);
          }}
          style={{ width: '10%' }}
        >
          Queue IDs
        </Button>
      </ButtonGroup>
      <form onSubmit={submitReplayLogs}>
        <Grid container spacing={1}>
          {inputType == 'form' && (
            <Grid item xs={2}>
              <Stack spacing={0.5}>
                <InputLabel id="user_id-label">Patient ID</InputLabel>
                <TextField
                  value={formData.patientID}
                  onChange={(evt) => {
                    setFormData({ ...formData, patientID: evt.target.value });
                    setIsSubmitted(false);
                  }}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={2}>
            <Stack spacing={0.5}>
              <InputLabel id="environment-label">Target Environment</InputLabel>
              <Select
                labelId="environment-label"
                value={formData.environment}
                onChange={(evt) => {
                  setFormData({ ...formData, environment: evt.target.value as Environment });
                  setIsSubmitted(false);
                }}
              >
                {environmentOptions.map((v, i) => (
                  <MenuItem
                    key={v + '-' + i}
                    disabled={!!v.match(/^--------/)}
                    value={v}
                  >
                    {v}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>
          {inputType == 'form' && (
            <>
              <Grid item xs={3}>
                <Stack spacing={0.5}>
                  <InputLabel id="date_from-label">From</InputLabel>
                  <TextField
                    type="date"
                    value={formData.dateFrom}
                    onChange={(evt) => {
                      setFormData({ ...formData, dateFrom: evt.target.value });
                      setIsSubmitted(false);
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack spacing={0.5}>
                  <InputLabel id="date_to-label">To</InputLabel>
                  <TextField
                    type="date"
                    value={formData.dateTo}
                    onChange={(evt) => {
                      setFormData({ ...formData, dateTo: evt.target.value });
                      setIsSubmitted(false);
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Stack spacing={0.5}>
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    value={formData.limit.toString()}
                    onChange={(evt) => {
                      setFormData({ ...formData, limit: parseInt(evt.target.value) });
                      setIsSubmitted(false);
                    }}
                  >
                    {limitOptions.map((v, i) => (
                      <MenuItem
                        key={v + '-' + i}
                        disabled={!!v.toString().match(/^--------/)}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
        {inputType == 'queue' && (
          <Stack spacing={0.5}>
            <InputLabel id="queue-ids">Queue IDs (50 limit)</InputLabel>
            <TextField
              id="queue-ids"
              value={inputQueueIds}
              multiline
              onChange={(evt) => {
                setInputQueueIds(evt.target.value);
                setIsSubmitted(false);
              }}
            />
            <Button
              variant="contained"
              onClick={() => setQueryQueueIds(inputQueueIds)}
              style={{ width: '20%' }}
            >
              Find Queues
            </Button>
          </Stack>
        )}
        {formData.environment != configEnvToEnvironment() && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <InputLabel id="username-label">Username</InputLabel>
                <TextField
                  value={formData.username}
                  onChange={(evt) => {
                    setFormData({ ...formData, username: evt.target.value });
                    setIsSubmitted(false);
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={0.5}>
                <InputLabel id="password-label">Password</InputLabel>
                <TextField
                  value={password}
                  type="password"
                  onChange={(evt) => {
                    setPassword(evt.target.value);
                    setIsSubmitted(false);
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
        {formErrorMessage && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Alert severity="error">{formErrorMessage}</Alert>
              </Stack>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Stack spacing={0.5}>
              <Button
                style={{ marginTop: '24px' }}
                variant="contained"
                type="submit"
              >
                Replay Logs
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack spacing={0.5}>
              <Button
                style={{ marginTop: '24px' }}
                variant="contained"
                onClick={() => {
                  setResetSentItems(true);
                  setIsSubmitted(false);
                }}
              >
                Remove Sent Queues
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack spacing={0.5}>
              <Button
                style={{ marginTop: '24px' }}
                variant="contained"
                onClick={() => handleDownloadCSV()}
                disabled={!createdMealUrls.length}
              >
                Download Sent Queues (CSV)
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <LogResultList
        items={queue}
        env={formData.environment}
        username={formData.username}
        password={password}
        isResultsLoading={foodResultsRes.isPending}
        isSubmitted={isSubmitted}
        resetSentItems={resetSentItems}
        setError={setFormErrorMessage}
        setIsSubmitted={setIsSubmitted}
        setResetSentItems={setResetSentItems}
        setCreatedMealUrls={setCreatedMealUrls}
      />
    </>
  );
};

const formatDate = (date: string) => {
  return moment.utc(date).local().format('YYYY-MM-DD');
};

const formatTime = (time: string) => {
  return moment.utc(time).local().format('HH:mm:ss');
};

const photoToBlob = async (url: string) => {
  const response = await fetch(url, {
    method: 'get',
    headers: { 'Authorization': `Bearer ${apiConfig.accessToken as string}` },
  });
  if (!response.ok) {
    throw new Error();
  }
  return await response.blob();
};

const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }
  return new File([u8arr], filename, { type: mime });
};

const blobToDataURL = async (blob: Blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const useTargetFoodApiClient = (opts: {
  env: Environment,
  username: string,
  password: string,
  isSubmitted: boolean,
}) => {
  const clientAuthRes = useAsyncResult<ReviewerAuthInfo | null>();
  const baseApiUrl = environments[opts.env].apiUrl;
  const axiosInstance = axios.create({ baseURL: baseApiUrl });
  const client = useMemo(() => {
    if (opts.env == configEnvToEnvironment()) {
      return foodApi;
    }
    if (!clientAuthRes.isDone || !clientAuthRes.result?.access_token) {
      return null;
    }
    const apiConfig = new Configuration({
      basePath: baseApiUrl + '/api',
    });
    apiConfig.accessToken = clientAuthRes.result.access_token;
    return new FoodApi(apiConfig, baseApiUrl, axiosInstance);
  }, [opts.env, clientAuthRes.isDone]);

  useEffect(() => {
    if (!opts.isSubmitted || opts.env == configEnvToEnvironment()) {
      return;
    }
    const apiConfig = new Configuration({
      basePath: baseApiUrl + '/api',
    });
    const oauth = new OauthApi(apiConfig, baseApiUrl, axiosInstance);
    clientAuthRes.bind(authenticate(opts.username, opts.password, oauth, apiConfig));
  }, [opts.isSubmitted]);

  return { client, clientAuthRes };
};

export const LogResultList = (props: {
  items: MealQueueItem[],
  env: Environment,
  username: string,
  password: string,
  isResultsLoading: boolean,
  isSubmitted: boolean,
  resetSentItems: boolean,
  setError: (newValue: string) => void,
  setIsSubmitted: (newValue: boolean) => void,
  setResetSentItems: (newValue: boolean) => void,
  setCreatedMealUrls: React.Dispatch<React.SetStateAction<string[]>>,
}) => {
  const { items, env, username, password } = props;
  const [sentItems, setSentItems] = useLocalStorage<number[]>('replayLogSentItems', []);
  const [amountSent, setAmountSent] = useState<number>(0);
  const [itemErrors, setItemErrors] = useState<number[]>([]);
  const [loadingItems, setLoadingItems] = useState<number[]>([]);
  const [groupSubmit, setGroupSubmit] = useState<boolean>(props.isSubmitted);
  const [individualSubmit, setIndividualSubmit] = useState<number>(0);
  const { client: targetEnvironmentFoodApi, clientAuthRes } = useTargetFoodApiClient({
    env,
    username,
    password,
    isSubmitted: groupSubmit || !!individualSubmit,
  });
  const { authInfo } = useAuth();

  const postItemToQueue = async (item: MealPhotoQueueResponse) => {
    setItemErrors(prev => prev.filter(id => id !== item.id));
    if (!targetEnvironmentFoodApi) {
      setItemErrors(prev => [...prev, item.id]);
      return;
    }
    setLoadingItems(prev => [...prev, item.id]);
    let patientContext: any = null;
    if (authInfo) {
      try {
        const res = await dataReviewApi.appApiQueuePatientContextGetPatientContext({
          data_reviewer_group_id: item.data_reviewer_group_id,
          data_reviewer_id: authInfo.reviewer_id,
          meal_photo_queue_id: item.id,
        });
        patientContext = res.data;
      } catch (error) {
        // Could not get patient context, don't wanna crash the entire function
      }
    }
    // We don't have meal info on the queue item, use some defaults for now
    const now = DateTime.now();
    const queueMetadata = {
      patient_context: !patientContext
        ? undefined
        : { diet_restrictions: patientContext.diet_restrictions || [] },
      copied_from: { env: config.ENV, queue_id: item.id },
      is_training: true,
      training_reference_meal_id: item.created_meal_id,
      training_reference_meal_items: item.existing_items,
    };
    try {
      if (!item.meal_photo_url) {
        const note = item.patient_note || '';
        if (!note || note.includes('recent items')) {
          setItemErrors(prev => [...prev, item.id]);
          setLoadingItems(prev => prev.filter(id => id !== item.id));
          setAmountSent(prev => prev + 1);
          return;
        }
        const newQueue = await targetEnvironmentFoodApi.appApiFoodFoodSearchNlpPostSearchByTextNlpQueue({
          search_text: item.patient_note ?? '',
          CreateSearchByTextNlpQueueRequest: {
            meal_time: formatTime(now.toString()),
            meal_date: formatDate(now.toString()),
            meal_name: 'snack',
            note: item.patient_note ?? '',
            queue_metadata: JSON.stringify(queueMetadata),
          },
        });
        props.setCreatedMealUrls(prev => [...prev, `${environments[env].auditorUrl}/meal/${newQueue.data.id}`]);
        setSentItems(prev => [...prev, item.id]);
        setAmountSent(prev => prev + 1);
        setLoadingItems(prev => prev.filter(id => id !== item.id));
        return;
      }
      const photoBlob = await photoToBlob(item.meal_photo_url);
      const dataURL = await blobToDataURL(photoBlob) as string;
      const photoFile = dataURLtoFile(dataURL, moment.utc().format());
      const newQueue = await targetEnvironmentFoodApi.appApiFoodFoodSearchSearchByPhotoFfeQueue({
        photo: photoFile,
        num_items: item.patient_number_of_items,
        meal_name: 'snack',
        meal_time: formatTime(now.toString()),
        meal_date: formatDate(now.toString()),
        note: item.patient_note || '',
        preparation_method: item.preparation_method,
        queue_metadata: JSON.stringify(queueMetadata),
      });
      props.setCreatedMealUrls(prev => [...prev, `${environments[env].auditorUrl}/meal/${newQueue.data.id}`]);
      setSentItems(prev => [...prev, item.id]);
      setAmountSent(prev => prev + 1);
    } catch (err) {
      setItemErrors(prev => [...prev, item.id]);
    }
    setLoadingItems(prev => prev.filter(id => id !== item.id));
  };

  useEffect(() => {
    setGroupSubmit(props.isSubmitted);
  }, [props.isSubmitted]);

  useEffect(() => {
    setItemErrors([]);
    if (!props.resetSentItems) {
      setAmountSent(items.filter((item) => sentItems.includes(item.id)).length);
      return;
    }
    props.setCreatedMealUrls([]);
    setSentItems([]);
    setAmountSent(0);
    props.setResetSentItems(false);
  }, [items, props.resetSentItems]);

  useEffect(() => {
    const item = items.find(item => item.id == individualSubmit);
    if (!item) {
      return;
    }
    if (clientAuthRes.isError) {
      props.setError('Invalid username or password');
      return;
    }
    if (!clientAuthRes.isDone && env != configEnvToEnvironment()) {
      return;
    }
    postItemToQueue(item).then(() => {
      setIndividualSubmit(0);
    });
  }, [individualSubmit, clientAuthRes.isDone, clientAuthRes.isError]);

  useEffect(() => {
    if (!groupSubmit) {
      setItemErrors([]);
      return;
    }
    if (clientAuthRes.isError) {
      props.setError('Invalid username or password');
      return;
    }
    if (!clientAuthRes.isDone && env != configEnvToEnvironment()) {
      return;
    }
    items.filter((item) => !sentItems.includes(item.id)).forEach(async (item) => {
      await postItemToQueue(item);
    });
  }, [groupSubmit, clientAuthRes.isDone, clientAuthRes.isError]);

  return (
    <Grid container spacing={3} sx={{ mt: 1 }}>
      {props.isResultsLoading && (
        <Grid item>
          <LoadingOutlined />
        </Grid>
      )}
      {!props.isResultsLoading && (
        <>
          {items.length > 0 && (
            <>
              <Grid item xs={2}>
                Sent: {amountSent}/{items.length}
              </Grid>
              <Grid item xs={2}>
                Failed: {itemErrors.length}/{items.length}
              </Grid>
            </>
          )}
          {!items.length && (
            <Grid item xs={12}>
              <Typography>
                <em>No results found</em>
              </Typography>
            </Grid>
          )}
          {items.map((item) => (
            <Grid
              item
              xs={12}
              key={item.id}
              sx={{
                pb: 3,
              }}
            >
              <LogResultListItem
                item={item}
                isLoading={loadingItems.includes(item.id)}
                isSent={sentItems.includes(item.id)}
                isError={itemErrors.includes(item.id)}
                individualSend={() => setIndividualSubmit(item.id)}
              />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export const LogResultListItem = (props: {
  item: MealPhotoQueueResponse,
  isSent: boolean,
  isLoading: boolean,
  isError: boolean,
  individualSend: () => void,
}) => {
  const { item, isLoading, isSent, isError } = props;

  const handleIndividualClick = async () => {
    props.individualSend();
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <PreviewImage queueItem={item} size="resized" />
      </Grid>
      <Grid item xs={10} zeroMinWidth>
        <Typography align="left" variant="subtitle1">
          <Link to={`/queue-item/${item.id}`} style={{ textDecoration: 'none' }}>
            {item.id}
            <QueueSummaryIcons queue={item} />
          </Link>
        </Typography>
        <Typography align="left" variant="caption" color="secondary">
          {item.queue_type} | {moment.utc(item.created_time).fromNow()}
        </Typography>
      </Grid>
      {/* Would be nice to align checkboxes to right, same as preview image */}
      <Grid item xs zeroMinWidth>
        {isLoading && <LoadingOutlined style={{ marginLeft: '6px' }} />}
        {!isSent && !isLoading && !isError && (
          <IconButton onClick={handleIndividualClick}>
            <CheckBoxOutlineBlank color="secondary" />
          </IconButton>
        )}
        {isError && (
          <IconButton onClick={handleIndividualClick}>
            <IndeterminateCheckBox color="error" />
          </IconButton>
        )}
        {isSent && <CheckBox color="primary" style={{ marginLeft: '6px' }} />}
      </Grid>
    </Grid>
  );
};
